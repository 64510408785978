<template>
    <!-- 填报资料 -->
    <div class="information">
        <div class="informationText">
            <div>说明：</div>
            <div>1、此资料为访客来访登记时需要填写的资料；</div>
            <div>2、部分信息为必须选择，不可取消；</div>
            <div>3、可“选择”的信息可视单位具体情况进行选择，以及是否必填；</div>
        </div>
        <div class="informationCon">
            <div class="informationContent">
                <el-table v-loading="loading" :data="tableData" style="width: 100%" height="100%">
                    <el-table-column prop="fieldName" label="信息名称" min-width="140">
                    </el-table-column>
                    <el-table-column label="选择" min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-switch @change="choiceChange" v-model="scope.row.choice" active-color="#1890ff" inactive-color="rgba(0,0,0,0.25)" :disabled="scope.row.isDis">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="信息类型" min-width="160" align="center">
                        <template slot-scope="scope">
                            <el-select @change="typeChange" v-model="scope.row.type" placeholder="请选择信息类型" :disabled="scope.row.isDis">
                                <el-option v-for="(item, index) in options" :key="index" :label="item" :value="index">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="可选值（请用,隔开）" min-width="240" align="center">
                        <template slot-scope="scope">
                            <!-- 显示： -->
                            <!-- 隐藏：禁用或者关闭 -->
                            <el-input @blur="valueBlur" v-if="!scope.row.isDis && scope.row.choice" v-model="scope.row.value" placeholder="请输入可选值（请用,隔开）" :disabled="scope.row.isDis"></el-input>
                            <div v-else>-</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="默认值" min-width="160" align="center">
                        <template slot-scope="scope">
                            <el-input @blur="defaultBlur" v-if="!scope.row.isDis" v-model="scope.row.default" placeholder="请输入默认值" :disabled="scope.row.isDis"></el-input>
                            <div v-else>-</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否必填" min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-switch @change="requiredCChange" v-model="scope.row.requiredC" active-color="#1890ff" inactive-color="rgba(0,0,0,0.25)" :disabled="scope.row.isDis">
                            </el-switch>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button @click="submitClick" class="submitSty" type="primary">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'information',
        props: {},
        data() {
            return {
                loading: false,
                // 表格
                tableData: [],
                // 信息类型
                options: ['文本输入', '单选项', '多选项'],
                // 传值数组
                settingsFields: [],
                placeId: '',
                informationData: [],
            }
        },
        created() {
            this.getItem()
        },
        methods: {
            // 赋值
            my() {
                // console.log('informationData: ', this.informationData)
                this.informationData.map((val, index) => {
                    this.tableData.push({
                        isDis: false,
                        fieldName: '',
                        choice: true,
                        type: null,
                        value: '',
                        default: '',
                        requiredC: false,
                        id: null,
                    })
                    this.tableData[index].fieldName = val.fieldName
                    this.tableData[index].id = val.id
                    if (index === 0 || index === 1 || index === 2) {
                        this.tableData[index].isDis = true
                    } else {
                        this.tableData[index].isDis = false
                    }
                    if (val.selected === 1) {
                        this.tableData[index].choice = true
                    } else {
                        this.tableData[index].choice = false
                    }
                    this.tableData[index].type = val.type - 1
                    this.tableData[index].value = val.optionalValue
                    this.tableData[index].default = val.defaultValue
                    if (val.required === 1) {
                        this.tableData[index].requiredC = true
                    } else {
                        this.tableData[index].requiredC = false
                    }
                })
                // console.log('this.tableData: ', this.tableData)
                this.tableData.map((val, index) => {
                    this.settingsFields.push({
                        fieldName: '',
                        selected: null,
                        type: null,
                        optionalValue: '',
                        defaultValue: '',
                        required: null,
                        id: null,
                    })
                    this.settingsFields[index].fieldName = val.fieldName
                    this.settingsFields[index].id = val.id
                    if (val.choice) {
                        this.settingsFields[index].selected = 1
                    } else {
                        this.settingsFields[index].selected = 0
                    }
                    this.settingsFields[index].type = val.type + 1
                    this.settingsFields[index].optionalValue = val.value
                    this.settingsFields[index].defaultValue = val.default
                    if (val.requiredC) {
                        this.settingsFields[index].required = 1
                    } else {
                        this.settingsFields[index].required = 0
                    }
                })
                // console.log('this.settingsFields: ', this.settingsFields)
            },
            // 填报资料
            getItem() {
                this.loading = true
                this.placeId = sessionStorage.getItem('placeId')
                this.$instance
                    .get('/v1/place/visited-settings', {
                        params: {
                            placeId: this.placeId,
                        },
                    })
                    .then(res => {
                        // console.log('res: ', res.data)
                        if (res.data.status === 200) {
                            this.loading = false
                            this.informationData = res.data.data
                            this.my()
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 选择 发生变化
            choiceChange() {
                this.tableData.map((val, index) => {
                    if (val.choice) {
                        this.settingsFields[index].selected = 1
                    } else {
                        this.settingsFields[index].selected = 0
                    }
                })
            },
            // 信息类型 发生变化
            typeChange() {
                this.tableData.map((val, index) => {
                    this.settingsFields[index].type = val.type + 1
                })
            },
            // 可选值 失去焦点
            valueBlur() {
                this.tableData.map((val, index) => {
                    this.settingsFields[index].optionalValue = val.value
                })
            },
            // 默认值 失去焦点
            defaultBlur() {
                this.tableData.map((val, index) => {
                    this.settingsFields[index].defaultValue = val.default
                })
            },
            // 是否必填 发生变化
            requiredCChange() {
                this.tableData.map((val, index) => {
                    if (val.requiredC) {
                        this.settingsFields[index].required = 1
                    } else {
                        this.settingsFields[index].required = 0
                    }
                })
            },
            // 点击 提交
            submitClick() {
                let value = { settingsFields: this.settingsFields }
                this.submitPost(value)
            },
            submitPost(value) {
                this.$instance
                    .post('/v1/place/visited-settings/submit', value)
                    .then(res => {
                        // console.log('res: ', res.data)
                        if (res.data.status === 200) {
                            this.$message({
                                message: '提交成功！',
                                type: 'success',
                            })

                            // 不同权限 被访问到的页面
                            let userInfo = sessionStorage.getItem('userInfo') + ''
                            if (
                                userInfo === '1' ||
                                userInfo === '3' ||
                                userInfo === '4'
                            ) {
                                this.$router.push('/content/company-admin')
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .information {
        width: calc(100% - 40px);
        margin: 20px;
        .informationText {
            padding: 20px 30px;
            background-color: #fff;
            margin-bottom: 20px;
            div {
                margin-bottom: 4px;
            }
        }
        .informationCon {
            height: calc(100% - 156px);
            background-color: #fff;
            // 表格
            & ::v-deep .informationContent {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: auto;
                padding: 0 30px;
                .submitSty {
                    width: 120px;
                    margin: 20px;
                }
                .el-table--fit {
                    .el-table__row {
                        .is-center {
                            .el-switch__core {
                                border-color: #dcdfe6 !important;
                            }
                            .el-input__inner {
                                height: 32px;
                                line-height: 32px;
                            }
                            i {
                                line-height: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
